import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getExpUserProfile } from "./ExpApi/expUser";
import { useWebNotification } from "@vueuse/core";

export const useNotificationPermissionState = () => useState('notificationPermission', () => useWebNotification({requestPermissions: false})?.permissionGranted?.value);

// export const useNotificationChannelState = () => useState('messageChannelState', () => false);
// export const useMessagePort = () => useState('messagePort');

export const usePushNotificationUtils = () => {
  const { pushToken, iOSPushCapability, pushPermissionRequest, pushPermissionState, pushTokenRequest, trackingPermissionRequest } = useiOSPWAUtils();
  // TODO: Make configurable via runtimeConfig
  const firebaseConfig = {
    apiKey: "AIzaSyDUIXLVe3g9HaEZ8YSy_BDqTU5CAG8VWEY",
    authDomain: "expertapi.firebaseapp.com",
    projectId: "expertapi",
    storageBucket: "expertapi.appspot.com",
    messagingSenderId: "573505169925",
    appId: "1:573505169925:web:426747df134d336a89ca6c",
  };

  const messaging = ref()

  onNuxtReady(() => {
    if (useDevice()?.isApple && iOSPushCapability.value == true) {
      pushPermissionState();
      getSubscriptionToken();
    } else if (useAppSettingsCookie().value.isNativeApp == false) {
      const app = initializeApp(firebaseConfig);
      messaging.value = getMessaging(app);
    } else {
      console.debug('will not load Firebase, because native app');
    }
    if (import.meta.client && !useDevice()?.isApple) {
      if (Notification?.permission == "granted") {
        useNotificationPermissionState().value = true;
      } else if (Notification?.permission == "default") {
        useNotificationPermissionState().value = false;
      } else if (Notification?.permission == "denied") {
        useNotificationPermissionState().value = false;       
      } else {
        useNotificationPermissionState().value = false;
      }
    }
  })

  const actualToken = useAppPushCookie();

  const tokenFetchUrl = computed(() => {
    return "/api/nea/topic-service/api/subscriptions/endpoint/" + actualToken.value;
  });

  const { data: subscription, refresh, execute, error } = useFetch<any>(tokenFetchUrl);

  watch(error, () => {
    if (error?.value?.statusCode == 404 && actualToken?.value != undefined) {
      console.debug('endpoint sub not found, subscribing all');
      subScribeAll();
    }
  })

  if (!useAppPushCookie()?.value && !useCurrentUserState()?.value) {
    getExpUserProfile();
  }

  if (subscription?.value?.endpoint == undefined && actualToken?.value == undefined) {
    console.debug('empty subscription endpoint')
  } else if (actualToken?.value != undefined) {
    execute();
  }

  function getSubscriptionToken() {
    if (useDevice()?.isApple) {
      return new Promise((resolve, reject) => {
        pushPermissionState();
        pushPermissionRequest();
        pushTokenRequest();
        watch(
          pushToken,
          () => {
            if (pushToken?.value) {
              if (typeof pushToken?.value == 'string') {
                actualToken.value = btoa(pushToken.value)
              } else {
                actualToken.value = btoa(JSON.stringify(pushToken?.value));
              }
              refresh();
              resolve(pushToken?.value);
              refreshCookie('expertAppPushToken')
            }
          },
          { immediate: true }
        );
        setTimeout(() => {
          console.error("did not resolve token in time");
          reject();
        }, 2000);
      });
    } else if (useAppSettingsCookie().value.isNativeApp == false) {
      let reso = getToken(messaging.value, {
        vapidKey: useRuntimeConfig()?.public?.pushPublicKey,
        serviceWorkerRegistration: useNuxtApp()?.$pwa?.getSWRegistration(),
      });
      reso.then((val) => {
        if (val != undefined && val != 'undefined') {
          let str = typeof val == 'string' ? val : JSON.stringify(val)
          if (str == 'undefined') {
            console.warn('UNDEFINED TOKEN');
          } else {
            actualToken.value = btoa(str);
            console.debug("got token", str);
            refreshCookie('expertAppPushToken')
          }
        } else {
          console.warn('token was undefined');
        }
      })
      return reso;
    } else {
      console.debug('got native android app, token should be handled automagically');
      return new Promise((resolve, reject) => {
        let intv = setInterval(() => {
          console.debug('refreshing cookie');
          refreshCookie('expertAppPushToken');
          if (actualToken.value != undefined) {
            console.debug('actualToken found!! ', actualToken);
            refresh();
            resolve(actualToken.value);
            clearInterval(intv);
          }
        }, 500);
        setTimeout(() => {
          console.debug('timeout, cleanup interval')
          clearInterval(intv);
          reject("timeout waiting for token");
        }, 2000);
      })
    }
  }
  async function subScribeAll() {
    if (!actualToken?.value) await getSubscriptionToken();

    if (!actualToken?.value) {
      throw createError({
        cause: {
          namespace: "Push",
          errorcode: 1,
          message: "Could not get Token",
        },
      });
    } else {
      console.log("got token", actualToken);
    }
    await $fetch("/api/nea/topic-service/api/subscriptions/subscribeAll", {
      method: "POST",
      body: {
        endpoint: actualToken?.value,
        storeId: useCurrentStore()?.value?.id,
      },
    });
    refresh();
  }
  async function ensurePermissions(permissionType: 'PUSH' | 'TRACKING') {
    if (iOSPushCapability.value == true) {
      if (permissionType == 'PUSH') {
        pushPermissionState();
        pushPermissionRequest();
      } else if (permissionType == 'TRACKING') {
        trackingPermissionRequest();
      }
    } else if (!useDevice()?.isApple) { // Android
      if (permissionType == 'PUSH') {
        if (useNotificationPermissionState()?.value == false || Notification?.permission != 'granted'){
          await requestPermission();          
        }
      }
    }
  }

  async function requestPermission() {
    console.debug('Requesting permission...');
    try {
      const permission = await Notification?.requestPermission();
      if(permission !== 'granted') {
        console.warn('navigating to get permission');
        await navigateTo(`intent://${useRuntimeConfig()?.public?.cookieDomain}/app#Intent;scheme=https;package=de.expert.brntgs.app.twa;S.messageExtra=permissions;end`, { external: true });
      }
      if (permission === 'granted') {
        console.debug('Notification permission granted.');
        useNotificationPermissionState().value = true;
      } else {
        useNotificationPermissionState().value = false;        
        console.warn('No permission granted')
        if (messaging.value != undefined) {
          let reso = await getToken(messaging.value, {
            vapidKey: useRuntimeConfig()?.public?.pushPublicKey,
            serviceWorkerRegistration: useNuxtApp()?.$pwa?.getSWRegistration(),
          });
          if (reso != undefined && Notification?.permission == 'granted') {
            useNotificationPermissionState().value = true;
            console.debug('got permission and token ', reso)
          } else {
            console.debug('current permission state', Notification?.permission)
          }
        }
      }
    } catch (err) {
      console.error('error getting permission', err);
      console.debug('current permission state', Notification?.permission)
    }
    if (useNotificationPermissionState()?.value == false) {
      return new Promise((resolve, reject) => {
        let intv = setInterval(() => {
          console.debug('refreshing cookie');
          refreshCookie('expertAppPushToken');
          if (actualToken.value != undefined || Notification?.permission == 'granted') {
            console.debug('actualToken or permission found!! ', actualToken);
            useNotificationPermissionState().value = true;
            resolve(true);
            clearInterval(intv);
          }
        }, 500);
        setTimeout(() => {
          console.debug('timeout, cleanup interval')
          clearInterval(intv);
          reject("timeout waiting for permission");
        }, 2000);
      })
    }
    // } else {
    //   console.debug('notifications denied')
    // }
  }
  return {
    appPushCookie: actualToken,
    subscription,
    getSubscriptionToken,
    subScribeAll,
    refreshSubscription: refresh,
    ensurePermissions,
    requestPermission,
    notificationPermissionState: useNotificationPermissionState()
  }
}
