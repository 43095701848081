<template>
  <div class="p-2 flex flex-col justify-between w-full h-full">
    <div class="w-full text-right" @click="$emit('closed')">
      <BasicIcon name="xmark-solid" class="w-4" style="color: red;" />
    </div>
    <div class="flex flex-col justify-between h-full pb-10 overflow-auto">
      <div class="flex flex-col gap-4">
        <img class="mx-auto w-full pointer-events-none" :src="entry.img" :alt="entry.alt" />
        <div class="text-3xl text-center font-extrabold" v-text="entry.headline" />

        <div v-if="notficationTrouble"
          class="bg-[#FFEEE8] relative rounded flex flex-col gap-2 text-sm p-2 overflow-auto">
          <div class="text-center font-bold p-2 text-error">Sie haben Push-Benachrichtigungen ggf. in den
            System-Einstellungen deaktiviert.</div>
        </div>

        <div class="text-sm" v-text="entry.text" />
      </div>
      <div class="py-4">

        <div class="bg-primary text-primary-contrast px-4 py-3 w-full text-center font-bold text-sm" @click="agreed">
          {{ agreeLabel }}
        </div>

        <div class=" bg-secondary text-secondary-contrast mt-4 px-4 py-3 w-full text-center font-bold text-sm"
          @click="skipped">
          {{ laterLabel }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps({
  permissionType: {
    type: String,
    default: "PUSH"
  },
  agreeLabel: {
    type: String,
    default: "Zustimmen"
  },
  laterLabel: {
    type: String,
    default: "Später"
  }
})
const emit = defineEmits(["agreed", "skipped", "closed"]);


import step1 from "~/assets/img/Gruppe 84.jpg";
import step3 from "~/assets/img/PermissionRationale_Step3.jpeg";
import step2 from "~/assets/img/Gruppe 96.jpg";

const { ensurePermissions } = usePushNotificationUtils();

const notficationTrouble = ref(false)
if (import.meta.client && props?.permissionType == "PUSH" && !useDevice()?.isApple) {
  notficationTrouble.value = (Notification?.permission == 'denied' && useAppSettingsCookie()?.value?.hasAskedForPushPermission);
}
function agreed() {
  emit('agreed');
  if (props?.permissionType == 'PUSH') {
    console.debug('user agreed to PUSH');
    useAppSettingsCookie().value.hasAskedForPushPermission = true;
    ensurePermissions('PUSH');
  }
}

function skipped() {
  emit('skipped');
  if (props?.permissionType == 'PUSH') {
    console.debug('user skipped push');
    useAppSettingsCookie().value.hasAskedForPushPermission = true;
    navigateTo('/app');
  }
}

const entry = props?.permissionType == 'PUSH' ?
  {
    id: "PUSH",
    img: step3,
    alt: "Abbildung von einem Megafon - Notification Teaser",
    headline: "Immer aktuell bleiben",
    text:
      "Um keine Angebote, Vorteilsaktionen oder App-Coupons zu verpassen, empfehlen wir, alle Mitteilungsoptionen zu aktivieren.",
  }
  : props?.permissionType == 'LOCATION' ?
    {
      id: "LOCATION",
      img: step1,
      alt: "experten Angebot der Woche Teaser",
      headline: "Exklusive Angebote & Coupons",
      text:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu tortor gravida, vehicula leo et, consectetur risus. ",
    }
    :
    {
      id: "REVIEW",
      img: step2,
      alt: "Zufrieden mit der App Teaser",
      headline: "Zufrieden mit deinem Einkauf?",
      text: "Wir hoffen, dass du mit deinem Einkauf zufrieden bist. Dein Feedback ist uns wichtig, um unsere App und unseren Service stetig zu verbessern. Wenn dir alles gefallen hat, dann gib doch eine Bewertung im App- Store und teile deine Erfahrung mit anderen Nutzern! Sollte es ein Problem gegeben haben, möchten wir gerne helfen! Sende uns dein Feedback direkt hier, damit wir deine Erfahrung verbessern können.",
    }
  ;


</script>
