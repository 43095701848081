declare const window: Window & {
  webkit?: any;
};
export const useiOSPWAUtils = () => {

  const pushLog = ref('');
  const pushToken = ref();
  const iOSPushCapability = ref(false);

  function logMessage(message: string) {
    let _toConsole = message;
    try {
      _toConsole = JSON.parse(message);
    } catch (e) { }
    console.debug(_toConsole);
    pushLog.value += `>: ${message}\r\n`
  }

  onNuxtReady(() => {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['push-permission-request'] && window.webkit.messageHandlers['push-permission-state']) {
      iOSPushCapability.value = true;
    }

    // @ts-ignore
    window.addEventListener('push-permission-request', (event: CustomEvent) => {
      if (event && event.detail) {
        logMessage(event.detail);

        switch (event.detail) {
          case 'granted':
            useNotificationPermissionState().value = true;
            // permission granted
            break;
          default:
            useNotificationPermissionState().value = false;
            // permission denied
            break;
        }
      }
    });

    // @ts-ignore
    window.addEventListener('push-permission-state', (event: CustomEvent) => {
      if (event && event.detail) {
        logMessage(event.detail);

        switch (event.detail) {
          case 'notDetermined':
            useNotificationPermissionState().value = false;
            // permission not asked                      
            break;
          case 'denied':
            useNotificationPermissionState().value = false;
            // permission denied
            break;
          case 'authorized':
          case 'ephemeral':
          case 'provisional':
            useNotificationPermissionState().value = true;
            // permission granted
            break;
          case 'unknown':
          default:
            // something wrong
            useNotificationPermissionState().value = false;
            break;
        }
      }
    });

    // @ts-ignore
    window.addEventListener('push-notification', (event: CustomEvent) => {
      if (event && event.detail) {
        // logMessage(JSON.stringify(event.detail));
        console.warn('got push notification', event.detail);
        if (event.detail?.link != undefined) {
          let totalLink = event.detail.link;
          if (event.detail.notificationId != undefined && !event.detail.link?.includes('notificationId=')) {
            totalLink += (event.detail.link?.includes('?') ? '&' : '?') + 'notificationId=' + event.detail?.notificationId;
            console.warn('navigating to ', totalLink);
            navigateTo(totalLink);
          }
        }
      }
    });

    // @ts-ignore
    window.addEventListener('push-token', (event: CustomEvent) => {
      if (event && event.detail) {
        logMessage(JSON.stringify(event.detail)?.substring(0, 10) + '...');
        if (typeof event?.detail == 'string') {
          pushToken.value = event.detail;
        } else {
          pushToken.value = JSON.stringify(event.detail);
        }
      }
    });

    // @ts-ignore
    window.addEventListener('tracking-permission-request', (event: CustomEvent) => {
      if (event && event.detail) {
        try {
          logMessage(JSON.stringify(event.detail)?.substring(0, 10) + '...');
          localStorage?.setItem('ATT', event.detail);
        } catch (err) {
          // 
        }
      }
    });
    // @ts-ignore
    window.addEventListener('tracking-permission-state', (event: CustomEvent) => {
      if (event && event.detail) {
        try {
          logMessage(JSON.stringify(event.detail)?.substring(0, 10) + '...');
          localStorage?.setItem('ATT', event.detail);
        } catch (err) {
          // 
        }
      }
    });


  });

  return {
    pushLog,
    pushToken,
    iOSPushCapability,
    pushPermissionRequest() {
      if (iOSPushCapability.value)
        window.webkit.messageHandlers['push-permission-request'].postMessage('push-permission-request');
    },

    pushPermissionState() {
      if (iOSPushCapability.value)
        window.webkit.messageHandlers['push-permission-state'].postMessage('push-permission-state');
    },

    pushSubscribeTopic(topic: string, eventValue: unknown, unsubscribe?: boolean) {
      if (iOSPushCapability.value) {
        window.webkit.messageHandlers['push-subscribe'].postMessage(JSON.stringify({
          topic, // topic name to subscribe/unsubscribe
          eventValue, // user object: name, email, id, etc.
          unsubscribe // true/false
        }));
      }
    },
    pushTokenRequest() {
      if (iOSPushCapability.value)
        window.webkit.messageHandlers['push-token'].postMessage('push-token');
    },
    trackingPermissionRequest() {
      if (iOSPushCapability.value)
        window.webkit.messageHandlers['tracking-permission-request'].postMessage('tracking-permission-request');
    }
  }
}
